import React, { useState } from "react"

const endPoint = "/api/email"

const FormMail = () => {
  const [inputs, setInputs] = useState({})
  const [busy, setBusy] = useState()
  const [status, setStatus] = useState("init")

  const handleChange = event => {
    const name = event.target.name
    const value = event.target.value
    setInputs(values => ({ ...values, [name]: value }))
  }

  const _onSubmit = async e => {
    e.preventDefault()
    setBusy(true)
    setStatus("Sending ...")

    let emailBody = ""
    for (const key in inputs) {
      console.log(key, inputs[key])
      emailBody += `<div>${key}: ${inputs[key]}</div>`
    }

    await fetch(endPoint, {
      method: "POST",
      body: JSON.stringify(emailBody),
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setBusy(false)
        setStatus(result.message)
        // setStatus("done")
      })
      .catch(error => {
        console.log("error", error)
        setBusy(false)
        setStatus(error)
      })
  }

  const fields = [
    {
      type: "text",
      name: "first-name",
      placeholder: "First name",
    },
    {
      type: "text",
      name: "last-name",
      placeholder: "Last name",
    },
    {
      type: "email",
      name: "email",
      placeholder: "Email",
    },
  ]

  return (
    <div className="form-mailto">
      <details>
        <summary className="cursor-s-resize- cursor-pointer ">
          Newsletter
        </summary>
        {status === "init" && (
          <form action="" onSubmit={_onSubmit}>
            {fields.map((item, i) => (
              <div key={i} className="form-row">
                <input
                  type={item.type}
                  placeholder={item.placeholder}
                  name={item.name}
                  value={inputs[item.name] || ""}
                  onChange={handleChange}
                  required
                />
              </div>
            ))}

            <div className="form-row">
              <button type="submit">Submit</button>
            </div>
          </form>
        )}
        {status !== "init" && <p>{status}</p>}
      </details>
    </div>
  )
}

export default FormMail
