import React from "react"
import { PrismicRichText } from "@prismicio/react"
import { useSiteMetadata } from "../hooks/useSiteMetadata"

const News = () => {
  const { settings } = useSiteMetadata()
  return (
    <div className="news">
      {settings.news && settings.news.length > 0 && (
        <div className="news mb-lg md:mb-sm text-red">
          {settings.news.map(({ item }, i) => (
            <article key={i}>
              <PrismicRichText field={item.richText} />
            </article>
          ))}
        </div>
      )}
    </div>
  )
}

export default News
