import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import { _linkResolver } from "../core/utils"
import FormMailto from "./ui/FormMailto"
import FormMail from "./ui/FormMail"
import News from "./News"

const query = graphql`
  query {
    prismicHeader {
      data {
        menu {
          item {
            uid
            type
            document {
              ... on PrismicPage {
                id
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const AsideRight = () => {
  const {
    prismicHeader: {
      data: { menu },
    },
  } = useStaticQuery(query)

  return (
    <aside className="aside-right relative mb-md">
      <div className="md:sticky top-header-height- md:top-md ">
        <News />
        <nav role="navigation" aria-label="Menu">
          <ul>
            {menu.map(({ item }) => (
              <li key={item.uid}>
                <Link to={_linkResolver(item)}>
                  {item.document.data.title.text}
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        {/* <FormMailto /> */}
      </div>
    </aside>
  )
}

export default AsideRight
