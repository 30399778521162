import React from "react"
import { PrismicRichText, PrismicImage } from "@prismicio/react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ExhibitionArticle = ({ input, h1 }) => {
  const { title, subtitle, date_from, date_to, texte, image_featured } = input
  // console.log(texte)
  return (
    <article className="mb-lg">
      <div className="header mb-sm">
        {subtitle && <div className="subtitle">{subtitle.text}</div>}
        {h1 && <h1>{title.text}</h1>}
        {!h1 && <h2>{title.text}</h2>}
        {date_from && date_to && (
          <div className="date">{`${date_from} — ${date_to}`}</div>
        )}
      </div>

      <figure className="mb-sm- mb-md">
        <GatsbyImage
          image={getImage(image_featured)}
          alt={image_featured.alt ? image_featured.alt : title.text}
        />
      </figure>

      <div className="texte text-justify">
        <PrismicRichText
          field={texte.richText}
          components={{
            // paragraph: ({ children }) => (
            //   <p className="paragraph">{children}</p>
            // ),
            image: ({ node }) => (
              <figure>
                <PrismicImage field={node} alt={node.alt} />
                {/* <GatsbyImage
                  image={getImage(node)}
                  alt={node.alt ? node.alt : title.text}
                /> */}
                {/* <figcaption>{node.alt || title.text}</figcaption> */}
                {/* <pre>{JSON.stringify(node, null, 2)}</pre> */}
              </figure>
            ),
          }}
        />
      </div>
    </article>
  )
}

export default ExhibitionArticle
