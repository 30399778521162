import { useStaticQuery, graphql } from "gatsby"

export const useSiteMetadata = () => {
  const { site, prismicSettings, allPrismicExhibition } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            siteTitle
            siteDescription
            siteUrl
            logo
            ogLanguage
            author
            twitter
            facebook
          }
        }
        prismicSettings {
          data {
            title {
              text
            }
            description {
              text
            }
            image {
              url
            }
            news {
              item {
                richText
              }
            }
          }
        }
        allPrismicExhibition(sort: { data: { date_from: DESC } }) {
          nodes {
            uid
            type
            data {
              title {
                text
              }
              date_from(formatString: "DD.MM.YYYY")
              date_to(formatString: "DD.MM.YYYY")
              image_featured {
                url
                alt
                dimensions {
                  width
                  height
                }
                gatsbyImageData(width: 200)
              }
              artist {
                document {
                  ... on PrismicArtist {
                    id
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  // console.log(global)
  return {
    buildTime: site.buildTime,
    site: site.siteMetadata,
    settings: prismicSettings.data,
    allExhibitions: allPrismicExhibition.nodes,
  }
}
