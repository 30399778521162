import React from "react"
import { graphql, Link } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import Seo from "../components/seo"
// import { PrismicRichText } from "@prismicio/react"
import ExhibitionArticle from "../components/ExhibitionArticle"
import Exhibitions from "../components/Exhibitions"
import { useSiteMetadata } from "../hooks/useSiteMetadata"
import News from "../components/News"

export const pageQuery = graphql`
  query ExhibitionBySlug($uid: String!) {
    prismicExhibition(uid: { eq: $uid }) {
      _previewable
      uid
      data {
        seo_title {
          text
        }
        seo_texte {
          text
        }
        seo_image {
          url
        }
        title {
          text
        }
        subtitle {
          text
        }
        date_from(formatString: "DD.MM.YYYY")
        date_to(formatString: "DD.MM.YYYY")
        texte {
          richText
        }
        image_featured {
          url
          alt
          dimensions {
            width
            height
          }
          gatsbyImageData(width: 2000)
        }
      }
    }
  }
`

const Exhibition = ({ data: { prismicExhibition } }) => {
  const { seo_title, seo_texte, seo_image } = prismicExhibition.data
  const { allExhibitions } = useSiteMetadata()
  return (
    <div>
      <Seo
        pageTitle={seo_title.text}
        pageDescription={seo_texte.text}
        pageBanner={seo_image.url}
        template="template-exhibition"
        page={true}
      />

      <div className="md:grid- grid-cols-3 gap-lg">
        <div className="mb-lg col-span-2-">
          <ExhibitionArticle input={prismicExhibition.data} h1={true} />
        </div>
      </div>
    </div>
  )
}

export default withPrismicPreview(Exhibition)
