import React from "react"
import L from "../images/petrine-logo.png"

const Logo = () => {
  return (
    <div
      className="logo"
      style={{
        maxWidth: "510px",
      }}
    >
      <img src={L} alt="" />
    </div>
  )
}

export default Logo
