import React from "react"
import clsx from "clsx"
// import { WrapperContext } from "./Layout";
// import Menu from "./Menu"
// import Burger from "./ui/Burger"
// import LogoAnimation from "./LogoAnimationSvg"
import { Link } from "gatsby"
import Logo from "./Logo"

// import LinkFade from "./ui/TransitionLinks/LinkFade"

const Header = () => {
  return (
    <header
      className={
        clsx(
          // "px-sm pb-sm md:px-md md:pb-lg md:mb-md z-10 w-full top-0 ",
          "px-md- pt-sm- pb-md- px-lg pt-md pb-lg"
        )
        //
      }
      role="banner"
    >
      <Link to="/">
        <Logo />
      </Link>
      {/* <div className="border h-[4px] bg-black"></div> */}
    </header>
  )
}

export default Header
