import React, { useEffect, createContext } from "react"
// import { useStaticQuery, graphql } from "gatsby"
import Header from "./Header"
import Footer from "./Footer"
import AsideLeft from "./AsideLeft"
import AsideRight from "./AsideRight"
// import Cursor from "./ui/Cursor"
import { motion, AnimatePresence } from "framer-motion"
// import locomotiveScroll from "locomotive-scroll"
import Demo from "../images/demo.png"

const WrapperContext = createContext({})

const duration = 0.35

const variants = {
  initial: {
    opacity: 0,
    y: 10,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    y: -10,
    transition: {
      duration: duration,
    },
  },
}

const Layout = ({ children, pageContext, location }) => {
  const settings = {}
  // const scrollRef = React.createRef()
  // useEffect(() => {
  //   // const scroll = new locomotiveScroll({
  //   //   el: scrollRef.current,
  //   //   smooth: true,
  //   // })
  // })

  useEffect(() => {
    _format()
    window.addEventListener("resize", _format)

    return () => {
      window.removeEventListener("resize", _format)
    }
  }, [])

  const _format = () => {
    document.documentElement.style.setProperty(
      "--app-height",
      window.innerHeight + "px"
    )

    const headerBounding = document
      .querySelector("header")
      .getBoundingClientRect()

    document.documentElement.style.setProperty(
      "--header-height",
      headerBounding.height + "px"
    )
  }

  return (
    <WrapperContext.Provider value={{ settings, location }}>
      {/* <Cursor color="#000" size="18" /> */}

      <div id="page">
        {/* <div className="fixed w-full">
          <img src={Demo} className="w-full" alt="" />
        </div> */}
        <Header />
        <div className="grid grid-rows-1 gap-sm md:grid-rows-1- md:grid-cols-4 md:gap-md px-sm md:px-offset-logo ">
          <AsideLeft />
          <main className="col-span-2" role="main">
            <AnimatePresence>
              <motion.div
                key={location.pathname}
                variants={variants}
                initial="initial"
                animate="enter"
                exit="exit"
                // ref={scrollRef}
              >
                {children}
              </motion.div>
            </AnimatePresence>
          </main>
          <AsideRight />
        </div>

        <Footer />
      </div>
    </WrapperContext.Provider>
  )
}

export { WrapperContext, Layout }
