/* eslint react/prop-types: 0, react/display-name: 0 */
import React, { lazy } from "react"
import Helmet from "react-helmet"
import PubSub from "pubsub-js"
import { Layout } from "./src/components/Layout"
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews"

import { _linkResolver } from "./src/core/utils"
import TemplateHome from "./src/templates/Home"
import TemplatePage from "./src/templates/Page"
import TemplateArtist from "./src/templates/Artist"
import TemplateExhibition from "./src/templates/Exhibition"
// import { withPrefix } from 'gatsby'
// const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>

// export default wrapPageElement

const _wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <script src="https://unpkg.com/pace-js@1.0.2/pace.min.js"></script>
      </Helmet>
      <PrismicPreviewProvider
        repositoryConfigs={[
          {
            repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
            linkResolver: _linkResolver,
            // componentResolver: componentResolverFromMap({
            //   home: TemplateHome,
            //   page: TemplatePage,
            //   artist: TemplateArtist,
            //   exhibition: TemplateExhibition,
            // }),
            componentResolver: {
              home: lazy(() => import("./src/templates/Home")),
              page: lazy(() => import("./src/templates/Page")),
              artist: lazy(() => import("./src/templates/Artist")),
              exhibition: lazy(() => import("./src/templates/Exhibition")),
            },
          },
        ]}
      >
        {element}
      </PrismicPreviewProvider>
    </>
  )
}

const _wrapPageElement = ({ element, props }) => {
  // console.log(Layout)
  return <Layout {...props}>{element}</Layout>
}

const _onClientEntry = () => {
  //console.log("We've started!")
  // callAnalyticsAPI()
  const isTouch = "ontouchstart" in window ? true : false

  if (isTouch) {
    document.documentElement.classList.add("touch")
  } else {
    document.documentElement.classList.add("no-touch")
  }
}

const _onRouteUpdate = ({ location }) => {
  // console.log("new pathname", location.pathname)
  PubSub.publish("ROUTE_UPDATE", location.pathname)
}

export { _wrapRootElement, _wrapPageElement, _onClientEntry, _onRouteUpdate }
